export default [
  {
    path: "/rooms",
    name: "Room",
    component: () => import("@/views/Rooms/"),
    redirect: { name: "RoomReservation" },
    children: [
      {
        path: "occupation",
        name: "RoomOccupation",
        component: () => import("@/views/Rooms/Occupation.vue"),
      },
      {
        path: "reservation",
        name: "RoomReservation",
        component: () => import("@/views/Rooms/Reservation.vue"),
      },
    ],
  },
  {
    path: "/mensa",
    name: "Mensa",
    component: () => import("@/views/Rooms/Mensa.vue"),
  },
];
