export default [
  {
    path: "/myprofile",
    name: "MyProfile",
    redirect: (to) => {
      return { name: "PersonContact", params: to.params };
    },
  },
  {
    path: "/myregister",

    component: () => import("@/views/People/PersonMe.vue"),
    children: [
      {
        path: "",
        name: "MyRegister",
        redirect: "register/list",
      },
      {
        path: "exams",
        name: "MyExams",
        component: () => import("@/views/People/PersonExams"),
      },
      {
        path: "register/:view/:date?",
        name: "MyRegisterView",
        component: () => import("@/views/People/PersonRegister/"),
        props: true,
      },
    ],
  },
  {
    path: "/person",
    name: "People",
    component: () => import("@/views/People/"),
  },

  {
    path: "/person/:id",
    component: () => import("@/views/People/Person"),
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    children: [
      {
        path: "",
        name: "Person",
        redirect: { name: "PersonContact" },
      },
      {
        path: "contact",
        name: "PersonContact",
        component: () => import("@/views/People/PersonContact"),
      },
      {
        path: "exams",
        name: "PersonExams",
        component: () => import("@/views/People/PersonExams"),
      },
      {
        path: "register",
        name: "Register",
        redirect: "register/list",
      },
      {
        path: "register/:view/:date?",
        name: "PersonRegister",
        component: () => import("@/views/People/PersonRegister/"),
        props: true,
      },
      {
        path: "profile",
        name: "PersonProfile",
        component: () => import("@/views/People/PersonProfile"),
      },
      {
        path: "courses",
        name: "PersonCourses",
        component: () => import("@/views/People/PersonCourses"),
      },
    ],
  },
];
